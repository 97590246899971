import React from 'react'
import { Link } from 'react-router-dom'
const RegistrationForm = props => {
    return (
        <div className="container_inscri">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-12 col-sm-12">
                        <div className="top_inscri text-center">
                        <Link className="linkBack" to={{ pathname: '/' }}>  <img src="assets/images/png/logo_navbar.png" alt="" /></Link>
                        </div>
                        <div className="title_inscri text-center">Compléter votre profil</div>
                        <div className="box_form_inscri">
                            <form>
                                <div className="form-group">
                                    <label for="prenom">Prénom</label>
                                    <input type="text" className="form-control form-club" id="prenom" name="prenom" onChange={props.setFirstName} value={props.firstName} />
                              
                                   {props.errorsname && (<span className="name-error ">{props.errorsname}</span>)}
                                </div>
                                <div className="form-group">
                                    <label for="nom">Nom</label>
                                    <input type="text" className="form-control form-club" id="nom" name="nom" onChange={props.setLastName} value={props.lastName} />
                             
                                    {props.errorslastname && (<span className="name-error ">{props.errorslastname}</span>)}
                                </div>
                                <div className="form-group">
                                    <label for="age">Age</label>
                                    <select onChange={props.setAge} className="form-control form-club" name="age" id="age">
                                        <option value="1" selected={props.age === '1'}>-18 ans</option>
                                        <option value="2" selected={props.age === '2'}>18-25 ans</option>
                                        <option value="3" selected={props.age === '3'}>26-35 ans</option>
                                        <option value="4" selected={props.age === '4'}>36-45 ans</option>
                                        <option value="5" selected={props.age === '5'}>+45 ans</option>
                                    </select>
                                </div>
                                <div className="groupe_radio">
                                    <div className="txt_radio">Vous êtes</div>
                                    <div className="inputs_radio">
                                        <label className="container_radio">Homme<input type="radio" onChange={props.setGenre} checked={props.genre === 'M'} value={'M'} name="radio" /><span className="checkmark"></span></label>
                                        <label className="container_radio">Femme<input type="radio" onChange={props.setGenre} checked={props.genre === 'F'} value={'F'} name="radio" /><span className="checkmark"></span></label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="clearfix"></div>
                        <div className="box_abonnement">
                            <label className="container_checkbox">Activer l’abonnement<input type="checkbox" onChange={props.setSubscription} /><span className="checkmark"></span></label>
                            {props.errorsheckmark && (<span className="checkmark-error">{props.errorsheckmark}</span>)}
                            <div className="desc_abonnement">3 jours gratuits puis 0.300 TND / jour à partir de votre solde téléphonique</div>
                        </div>
                        <div className="text-center"><button className="btn_club" onClick={props.saveForm}>Terminer</button></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegistrationForm
