import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactReadMoreReadLess from "react-read-more-read-less";
import API from '../../Backend/API'

import Footer from '../Footer'
import Loading from '../../Loading'


import leftarrow from './../../assets/images/png/left_arrow.png'
import rightarrow from './../../assets/images/png/right_arrow.png'
import posimg from './../../assets/images/png/pos.png'


import './index.css'



class DetailsProduct extends Component {


    state = {
        partner: {
            partner_name: '-',
            partner_logo: '-',
            partner_location_images: [],
            partner_category: [],

        },
        promotions: [{
            promotion_id: '-',
            promotion_title: '-',
            promotion_desc: '-',
        }],
        locations: [{
            promotiopartner_location_idn_id: '-',
            partner_location_nom: '-',
            partner_location_addr: '-',
        }],
        expanded: 'panel2',
        latitude: '',
        longitude: '',
        loading: false,
    }

    getMyLocation() {
        const location = window.navigator && window.navigator.geolocation

        if (location) {
            location.getCurrentPosition((position) => {
                this.setState({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                })
            }, (error) => {
                this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
            })
        }

    }

    getPosition = () => {
        return new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
    }

    getPartner = async () => {
        let query = '';
        let latitude = localStorage.getItem('latitude');
        let longitude = localStorage.getItem('longitude');

        query = `&latitude=${latitude}&longitude=${longitude}`

        if (!latitude || !longitude) {
            await this.getPosition().then(res => {
                localStorage.setItem('latitude', res.coords.latitude)
                localStorage.setItem('longitude', res.coords.longitude)
                query = `&latitude=${res.coords.latitude}&longitude=${res.coords.longitude}`
            }).catch(res => {
                query = ''
            })
        }

        try {
            API.get(`partner/${this.props.match.params.product_id}?${query}`).then(res => {
                this.setState(res?.data?.data);
                this.setState({
                    ...this.state,
                    loading: false
                })
            }).catch(error => {
                this.setState({
                    ...this.state,
                    loading: false
                })
            })

        } catch (error) {
            this.setState({
                ...this.state,
                loading: false
            })
        }
    }

    componentDidMount() {
        this.getMyLocation()
        this.setState({
            loading: true,
        });

        this.getPartner();
    };

    handleChange = (panel) => (event, isExpanded) => {
        if (isExpanded) {
            this.setState({
                expanded: panel
            });
        } else {
            this.setState({
                expanded: false
            });
        }
    };

    handleMap = () => {
        this.props.history.push('/map-partner', { locations: this.state.locations, color: this.state.partner.partner_category.partner_category_color })
    }
    km = (distance) => {
        let dist = distance / 1000;
        return dist.toFixed(1) + " km"
    }

    scanPromotion = (promotion_id) => {

        let qrCode = this.props.location.state?.qrCode;
        if (qrCode) {
            API.post(`client/scan-promotion?${promotion_id ? `promotion_id=${promotion_id}&` : ''}qrCode=${qrCode}`).then(res => {
                this.props.history.push({
                    pathname: `/promotion`,
                    state: { qrCode: res.data.data }
                });
            })
        } else {
            this.props.history.push({
                pathname: `/scanqr`,
                state: { id: promotion_id }
            });

        }

    }

    render() {
        const { loading } = this.state

        return loading ? <Loading /> : (

            <div className="DetailProduct">

                <section className="section_produit_details">
                    {this.state.partner.partner_location_images.map((locimg, index) =>
                        <div key={index} className="partner_location_images">
                            <img src={locimg} alt="" />
                            <Link className="link_back" to={{ pathname: '/' }}>
                                <img src={leftarrow} alt="" />
                            </Link>
                            <div className="partner_logo">
                                <img src={this.state.partner.partner_logo} alt="" />
                            </div>
                        </div>
                    )}
                    <div className="wrap_details_product">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-md-12 col-sm-12">
                                    <div className="box_details_product">
                                        <div className="partner_category_name">
                                            {this.state.partner.partner_category.partner_category_name}
                                        </div>
                                        <div className="partner_name">
                                            {this.state.partner.partner_name}
                                        </div>
                                        <div className="partner_distance">
                                            {
                                                this.state.partner.distance ?
                                                    <div className="txt_distance">à {this.km(this.state.partner.distance)} de votre position</div>
                                                    :
                                                    ''
                                            }
                                            <div className="pos_img" onClick={this.handleMap} ><img src={posimg} alt="" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrap_accordion">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-md-12 col-sm-12">
                                    <Accordion expanded={this.state.expanded === 'panel1'} onChange={this.handleChange('panel1')}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                                            <Typography className="title_accordion">Points de vente</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="wrap_locations_details">
                                                {this.state.locations.map((loc) =>
                                                    <div key={loc.partner_location_id} className="promotion_details">
                                                        <div className="box_content_promo">
                                                            <div className="title_promotion">{loc.partner_location_nom}</div>
                                                            <div className="desc_promotion">{loc.partner_location_addr}</div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={this.state.expanded === 'panel2'} onChange={this.handleChange('panel2')}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                                            <Typography className="title_accordion">Remises</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="wrap_promotion_details">
                                                {this.state.promotions.map((promo) =>
                                                    <div key={promo.promotion_id} className="promotion_details">
                                                        <div className="box_content_promo">
                                                            <div className="title_promotion">{promo.promotion_title}</div>
                                                            <div className="desc_promotion">
                                                                <ReactReadMoreReadLess
                                                                    charLimit={70}
                                                                    readMoreText={"Lire la suite ▼"}
                                                                    readLessText={".Lire moin ▲"}
                                                                    readMoreClassName="read-more-less--more"
                                                                    readLessClassName="read-more-less--less"
                                                                >
                                                                    {promo.promotion_desc}
                                                                </ReactReadMoreReadLess>

                                                            </div>
                                                        </div>
                                                        <span className="link_qr" onClick={() => this.scanPromotion(promo.promotion_id)} >
                                                            <span className="picto_link_qr"><img src={rightarrow} alt="" /></span>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}
export default DetailsProduct