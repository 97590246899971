import React, { useState, useContext } from 'react'
import { Router as BrowserRouter, Redirect, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { userContext } from "./Context/userContext";

import Index from "./Component/Index";
import Categories from "./Component/Categories";
import Promotion from "./Component/Promotion";
import ScanQr from "./Component/ScanQr";
import Map from "./Component/Map";
import DetailsProduct from "./Component/DetailsProduct";
import DetailsPage from "./Component/DetailsPages";
import Intro from "./Component/Intro";
import PaiementPhone from "./Component/PaiementPhone";
import Paiement from "./Component/Paiement";
import PaiementDetail from "./Component/PaiementDetails";
import PaiementResults from "./Component/PaiementResults";
import Historiques from "./Component/Historiques";
import Client from "./Pages/Client";
import MapPartner from "./Pages/MapPartner";
import Login from "./Component/Auth/Login";
import Register from "./Component/Auth/Register";
import history from './helpers/history';

const App = (props) => {

    const { application, user } = useContext(userContext)
    const [update, setUpdate] = useState(false)

    return (
        <div className="App">
            <BrowserRouter history={history}>
                {
                    application.show_intro ? (
                        <Intro {...props} setUpdate={setUpdate} />
                    ) :
                        <>
                            <Route exact path="/login" render={(props) => user.login ? <Redirect push to='/' /> : <Login setUpdate={setUpdate} />} />
                            <Route exact path="/register" render={(props) => user.login ? <Redirect push to='/' /> : <Register />} />
                            <Route exact path="/" render={(props) => user.login ? <Index /> : <Redirect push to='login' />} />
                            <Route path="/categories" render={(props) => user.login ? <Categories /> : <Redirect push to='login' />} />
                            <Route path="/scanqr" render={(props) => user.login ? <ScanQr /> : <Redirect push to='login' />} />
                            <Route path="/promotion" render={(props) => user.login ? <Promotion /> : <Redirect push to='login' />} />
                            <Route path="/map" render={(props) => user.login ? <Map /> : <Redirect push to='login' />} />
                            <Route path="/client" render={(props) => user.login ? <Client /> : <Redirect push to='login' />} />
                            <Route path="/paiementdetail" render={(props) => user.login ? <PaiementDetail /> : <Redirect push to='login' />} />
                            <Route path="/paiementresults" render={(props) => user.login ? <PaiementResults /> : <Redirect push to='login' />} />
                            <Route path="/paiement" render={(props) => user.login ? <Paiement /> : <Redirect push to='login' />} />
                            <Route path="/paiementphone" render={(props) => user.login ? <PaiementPhone /> : <Redirect push to='login' />} />
                            <Route path="/historiques" render={(props) => user.login ? <Historiques /> : <Redirect push to='login' />} />
                            <Route path="/product/:product_id/:product_name?" render={(props) => user.login ? <DetailsProduct {...props} /> : <Redirect push to='login' />} />
                            <Route path="/map-partner" render={(props) => user.login ? <MapPartner /> : <Redirect push to='login' />} />
                            <Route exact path="/page/:page_slug" render={(props) => user.login ? <DetailsPage {...props} /> : <Redirect push to='login' />} />
                        </>
                }

            </BrowserRouter>
        </div>
    );

}

export default App
