import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { userContext } from "../../Context/userContext";

const Error = (props) => {

    const [transaction, setTransaction] = useState('')
    const [errorCode, setErrorCode] = useState('')
    const [error, setError] = useState('')
    const [phone, setPhone] = useState('')

    useEffect(() => {
        for (const val of props.data) {

            if (val.includes("error_code")) {
                setErrorCode(val.split('error_code=')[1])
            }

            if (val.includes("session_id")) {
                setTransaction(val.split('session_id=')[1])
            }

            if (val.includes("msisdn")) {
                setPhone(val.split('msisdn=%2B216')[1])
            }

            if (val.includes("error_desc")) {

                if (errorCode == 1059) {
                    setError("La transaction n’a pas pu aboutir, veuillez réessayer plus tard")
                } else {
                    setError(val.split('error_desc=')[1].split('+').join(' '))
                }
            }
        }
    }, [])

    const { user, application } = useContext(userContext)
    const history = useHistory();

    const handleClick = () => {
        if (user.login) {
            history.push('/')
        } else {
            application.show_intro = true;
            user.login = false;
            localStorage.setItem('show_intro', 'true');
            history.push('/')
        }
    }
    return (<>
        <div className="AppContent">
            <div className="WrapPaiementResult">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-md-12 col-sm-12 box-paiemnt">
                            <div className="title_gris text-center"></div>
                            <div className="title_error text-center">{error && error}</div>
                            <div className="title_gris text-center" style={{ marginBottom: '2px' }}>Réference de transaction : {transaction}</div>
                            <div className="title_gris text-center" style={{ marginTop: '2px' }}>Numéro :{phone}</div>

                            <div className="box_renvoyer_link text-center marg-top-55">
                                <span className="standard_link" onClick={handleClick}>Retour à la page d'accueil</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Error
