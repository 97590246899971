import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { ApiStore } from '../../../Backend/API'
import RegistrationForm from '../Screens/RegistrationForm'
import SubmitPhone from '../Screens/SubmitPhone'

import './../Screens/styles.scss'

const Register = props => {
    const history = useHistory();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [age, setAge] = useState('2')
    const [genre, setGenre] = useState('M')
    const [subscription, setSubscription] = useState(false)
    const [phone, setPhone] = useState('216')
    const [phoneError, setPhoneError] = useState(false)
    const [errorsname, setErrorsname] = useState('')
    const [errorslastname, setErrorslastname] = useState('')
    const [errorsheckmark, setErrorsheckmark] = useState('')
    // SUBMITTING_NUMBER, PROCESSING_SUBSCRIPTION, REGISTRATION_FORM
    const [step, setStep] = useState('REGISTRATION_FORM')

    const handleFirstNameChange = data => { setFirstName(data.target.value) }
    const handleLastNameChange = data => { setLastName(data.target.value) }
    const handleAgeChange = data => { setAge(data.target.value) }
    const handleGenreChange = data => { setGenre(data.target.value) }
    const handleSubscriptionChange = data => { setSubscription(data.target.checked) }

    const saveForm = data => {
        // Just go to the phone number screen, the form is already saved in the state
        var subscriptionIsValid = false;
        var lastIsValid = false;
        var firstIsValid = false;

        if (subscription === false) {
            setErrorsheckmark("Ce champs est obligatoire")
        } else {
            subscriptionIsValid = true;
            setErrorsheckmark('')
        }

        if (!firstName) {
            setErrorsname("Ce champs est obligatoire");
        } else {
            if (typeof firstName !== "undefined") {
                if (!firstName.match(/^[a-zA-Z ]+$/)) {

                    setErrorsname("Veuillez vérifier le prénom");
                } else {
                    firstIsValid = true;
                    setErrorsname("");
                }
            }
        }
        if (!lastName) {
            setErrorslastname("Ce champs est obligatoire");
        } else {
            if (typeof lastName !== "undefined") {
                if (!lastName.match(/^[a-zA-Z ]+$/)) {

                    setErrorslastname("Veuillez vérifier le nom");
                } else {
                    lastIsValid = true;
                    setErrorslastname("");
                }
            }
        }


        if (lastIsValid && firstIsValid && subscriptionIsValid) {
            setStep('SUBMITTING_NUMBER')
        }
    }

    const submitPhone = data => {
        ApiStore.post('signup', {
            client_prenom: firstName,
            client_nom: lastName,
            client_telephone: phone.substr(3, 8),
            client_gender: genre,
            client_age: age,
            country_id: 1, // This means Tunisia
            client_phone_id: Math.random(), // This is the regID, check if we could get the identity of the browser
            client_phone_os: 4 // This means web
        }).then(response => {
            localStorage.setItem('token', response.data.data.token.access_token)
            localStorage.setItem('full_name', response.data.data.client.client_prenom + ' ' + response.data.data.client.client_nom)
            localStorage.setItem('subscription', !!response.data.data.client.client_subscribed)

            if (response.data.data.url_payment) {
                window.location = response.data.data.url_payment
            } else {
                history.push('/')
            }
        }).catch(error => {
            if ([409, 422].includes(error.response.data.status_code)) {
                setPhoneError(error.response.data.message)
            }
        })
    }

    const goBack = data => {
        setStep(data)
    }

    switch (step) {
        case 'REGISTRATION_FORM':
            return <RegistrationForm
                firstName={firstName}
                lastName={lastName}
                age={age}
                genre={genre}
                subscription={subscription}
                errorsname={errorsname}
                errorslastname={errorslastname}
                errorsheckmark={errorsheckmark}
                setFirstName={handleFirstNameChange}
                setLastName={handleLastNameChange}
                setAge={handleAgeChange}
                setGenre={handleGenreChange}
                setSubscription={handleSubscriptionChange}

                saveForm={saveForm}
            />

        case 'SUBMITTING_NUMBER':
            return <SubmitPhone status={"register"} phone={phone} setPhone={setPhone} phoneError={phoneError} sendSms={submitPhone} goBack={goBack} />

        default:
            return (<>Veuillez actualiser la page.</>)
    }
}

export default Register
