import React, { useState, useEffect, Component, useMemo } from "react";

import "bootstrap/dist/css/bootstrap.css"; // or include from a CDN
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import RangeSlider from "react-bootstrap-range-slider";
import leftArrow from "./../../assets/images/png/left_arrow-map.png";
import iconmapsvg from "./../../assets/images/png/png.png";

import Header from "../Header";
import Footer from "../Footer";
import API from "./../../Backend/API";
import "./index.css";
import {
  Map,
  InfoWindow,
  GoogleApiWrapper,
  Circle,
  Marker,
} from "google-maps-react";
//import Marker from './../Marker';
import { userContext } from "../../Context/userContext";

const MapContainer = (props) => {
  const [latitude, setLatitude] = useState(36.8413415);
  const [longitude, setLongitude] = useState(10.1479292);
  const [zoom, setZoom] = useState(10);
  const [radius, setRadius] = useState(18000);
  const [locations, setLocations] = useState([]);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [displayfilter, setDisplayfilter] = useState(false);
  const [valuedistance, setValuedistance] = useState(15000);
  const [distanceChange, setDistanceChange] = useState(15000);
  const [categoriesfilter, setCategoriesfilter] = useState([]);
  const [categchecked, setCategchecked] = useState(0);




  function onMarkerClick(props, marker, e) {


    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);

  }



  function handleSubmit() {
    setDisplayfilter(!displayfilter)
    var array = [];
    const checkboxes = document.querySelectorAll(
      "input[type=checkbox]:checked"
    );
    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
    }
    setCategchecked(array);
  }

  useEffect(() => {
    setLocations([]);

    if (valuedistance) {
      setRadius(parseInt(valuedistance))
      if (parseInt(valuedistance) > 20000 && parseInt(valuedistance) <= 30000) {
        setZoom(9);
      } else if (parseInt(valuedistance) > 20000 && parseInt(valuedistance) <= 20000) {
        setZoom(10);
      }
      else if (parseInt(valuedistance) > 15000 && parseInt(valuedistance) <= 20000) {
        setZoom(10);
      }
      else if (parseInt(valuedistance) > 10000 && parseInt(valuedistance) <= 15000) {
        setZoom(10);
      }
      else if (parseInt(valuedistance) > 5000 && parseInt(valuedistance) <= 10000) {
        setZoom(11);
      }
      else if (parseInt(valuedistance) > 2000 && parseInt(valuedistance) <= 5000) {
        setZoom(12);
      }
      else if (parseInt(valuedistance) == 2000) {
        setZoom(13);
      }
      else if (parseInt(valuedistance) == 1000) {
        setZoom(14);
      } else {
        setZoom(11);
      }
    }


    const location = window.navigator && window.navigator.geolocation

    if (location) {
      location.getCurrentPosition((position) => {
        setLatitude(parseFloat(position.coords.latitude));
        setLongitude(parseFloat(position.coords.longitude));
      }, (error) => {
        setLatitude('');
        setLongitude('');
      })
    }

    API.get("partners-locations?latitude=" + latitude + "&longitude=" + longitude + "&distance=" + valuedistance + "&categories=" + categchecked).then((res) => {
      setLocations(res.data.data);
    });

  }, [categchecked, distanceChange, latitude, longitude]);

  useEffect(() => {
    API.get("categories-partners").then((cat) => {
      setCategoriesfilter(cat.data.data);
      document.querySelectorAll('input[type="checkbox"]').forEach(e => e.checked = true);

    });
  }, []);


  const locationsListe = useMemo(
    () =>
      locations.map((item, index) => {

        return (
          <Marker
            position={{
              lat: item.partner_location_gps.split(",")[0],
              lng: item.partner_location_gps.split(",")[1],
            }}
            key={index}

            icon={{
              path:
                "M7.47 5.47c4.627-4.627 12.128-4.627 16.755 0 4.615 4.631 4.615 12.124-.002 16.755l-7.81 7.81-.09.078c-.314.233-.758.206-1.042-.078l-7.811-7.81-.231-.238C2.845 17.345 2.922 10.018 7.47 5.47zm8.377 3.2c-2.65 0-4.8 2.149-4.8 4.8 0 2.65 2.15 4.8 4.8 4.8 2.58 0 4.684-2.034 4.796-4.586l.004-.214c0-2.58-2.034-4.683-4.586-4.795l-.214-.005z",
              fillColor: item.partner_category_color,
              fillOpacity: 1,
              strokeWeight: 0,
              scale: 1,
            }}
            lat={item.partner_location_gps.split(",")[0]}
            lng={item.partner_location_gps.split(",")[1]}
            color={item.partner_category_color}
            name={item.partner_location_nom}
            title={item.partner_location_nom}
            alt={item.partner_location_nom}
            onClick={onMarkerClick}

          >
            {item.partner_location_nom}

          </Marker>
        );
      }),
    [locations]
  );

  return (
    <div className="Containerpag paddingBottom">
      <Header />
      <div className={displayfilter ? "Filter-menu active" : "Filter-menu"}>
        <div className="Headerfilter">
          <div className="retours" onClick={() => setDisplayfilter(!displayfilter)}>
            {" "}
            <img src={leftArrow} /> Filter{" "}
          </div>
          <div className="clearfix"></div>

          <div>
            <ul>
              {categoriesfilter &&
                categoriesfilter.map((item, index) => {
                  return (
                    <li key={index}>
                      <label className="container_checkbox">
                        {item.partner_category_name}
                        <input
                          type="checkbox"
                          name="categ[]"
                          value={item.partner_category_id}
                          id={"checked" + item.partner_category_id}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  );
                })}
            </ul>

            <div className="action_bt">
              <div className="enreg" value="Enregistrer" onClick={handleSubmit}>
                Enregistrer{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Map" >

        <Map initialCenter={{ lat: latitude, lng: longitude }} defaultZoom={zoom} zoom={zoom} defaultOptions={{ fullscreenControl: false }} google={props.google} zoomControl={false}>
          <Circle
            radius={radius}
            center={{ lat: latitude, lng: longitude }}
            strokeColor="#ccc9dbd1"

            strokeOpacity={1}

            fillColor="#0c4c3489"
            fillOpacity={0.2}

            stroke-width="4" stroke-dasharray="4" stroke-dashoffset='86'
          />
          <Marker
            key={1000}
            position={{
              lat: latitude,
              lng: longitude,
            }}
            icon={{
              url: iconmapsvg
            }}
            tooltip={"Mon position"}
            name={"Mon position"}
          />



          <InfoWindow marker={activeMarker} visible={showingInfoWindow}>

            <h5>{selectedPlace.name}</h5>

          </InfoWindow>
          {locationsListe}


        </Map>
        <div className="resultmap">à <strong>{valuedistance / 1000} km </strong> :   {locationsListe.length} enseignes trouvés</div>
        <div className="filter">
          <div className="RangeSlider">
            <div className="min-km"> 1km </div>
            <RangeSlider
              min={1000}
              max={30000}
              value={valuedistance}
              step={1000}
              onAfterChange={(event) => setDistanceChange(event.target.value)}
              onChange={(event) => setValuedistance(event.target.value)}
            />
            <div className="min-km"> 30km</div>
          </div>
          <div className="textfilter" onClick={() => setDisplayfilter(!displayfilter)}>
            Filter
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
})(MapContainer);
