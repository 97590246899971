import React from 'react'

let login = (localStorage.getItem('login') ?? 'false') == 'true'
let token = localStorage.getItem('token') ?? '1181|C4tYeDd0HNYPylEmtJIvqY4tUowD5f52096DJyls'
let language = localStorage.getItem('language') ?? 'en'
let show_intro = (localStorage.getItem('show_intro') ?? 'true') == 'true'
let full_name = localStorage.getItem('full_name') ?? 'Unknown'
let subscription = (localStorage.getItem('subscription') ?? 'false') == 'true'
let token_store = process.env.REACT_APP_QSFJKLD;

const userContext = React.createContext({ user: { login, token, language, token_store, full_name, subscription }, application: { show_intro } })

export { userContext }