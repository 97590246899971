import React, { useState } from 'react'
import API from '../../Backend/API'
import './index.css'

const Index = ({ client, setPage, setClient }) => {

    const [firstName, setFirstName] = useState(client.client_prenom)
    const [lastName, setLastName] = useState(client.client_nom)
    const [age, setAge] = useState(client.client_age + "")
    const [genre, setGenre] = useState(client.client_gender)
    const [error, setError] = useState('')

    const handleUpdate = () => {
        API.put(`/client`, { client_prenom: firstName, client_nom: lastName, client_gender: genre, client_age: age }).then((res) => {

            setClient({
                ...client,
                client_prenom: res.data.data.client_prenom,
                client_nom: res.data.data.client_nom,
                client_gender: res.data.data.client_gender,
                client_age: parseInt(res.data.data.client_age),
            });

            setPage(0)
        }).catch(error => {
            setError(error.response.data.message)
        });

    }

    return (
        <div className="container_inscri">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-12 col-sm-12">
                        <div style={{ marginBottom: "20px" }} className="title_inscri text-center mb-4">Modifier votre profil</div>
                        <div className="box_form_inscri" >
                            <form>
                                <div className="form-group">
                                    <label for="prenom">Prénom</label>
                                    <input type="text" className="form-control form-club" id="prenom" name="prenom" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                                </div>
                                <div className="form-group">
                                    <label for="nom">Nom</label>
                                    <input type="text" className="form-control form-club" id="nom" name="nom" onChange={(e) => setLastName(e.target.value)} value={lastName} />
                                </div>
                                <div className="form-group">
                                    <label for="age">Age</label>
                                    <select onChange={(e) => setAge(e.target.value)} className="form-control form-club" name="age" id="age">
                                        <option value="1" selected={age === '1'}>-18 ans</option>
                                        <option value="2" selected={age === '2'}>18-25 ans</option>
                                        <option value="3" selected={age === '3'}>26-35 ans</option>
                                        <option value="4" selected={age === '4'}>36-45 ans</option>
                                        <option value="5" selected={age === '5'}>+45 ans</option>
                                    </select>
                                </div>
                                <div className="groupe_radio">
                                    <div className="txt_radio">Vous êtes</div>
                                    <div className="inputs_radio">
                                        <label className="container_radio">Homme<input type="radio" onChange={(e) => setGenre(e.target.value)} checked={genre === 'M'} value={'M'} name="radio" /><span className="checkmark"></span></label>
                                        <label className="container_radio">Femme<input type="radio" onChange={(e) => setGenre(e.target.value)} checked={genre === 'F'} value={'F'} name="radio" /><span className="checkmark"></span></label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {error ? <p className="phone-error" style={{ marginBottom: "20px" }}>{error}</p> : ''}

                        <div className="clearfix"></div>
                        <div className="text-center"><button className="btn_club" onClick={handleUpdate}>Modifier </button></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
