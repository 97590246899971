import React, { useContext } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import right_arrow_next from "./../../../assets/images/png/right_arrow_next.png";
import left_arrow_map from "./../../../assets/images/png/left_arrow-map.png";
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom'
import { userContext } from '../../../Context/userContext';
const SubmitPhone = props => {
    const history = useHistory();
    const { application, user } = useContext(userContext)

    const handleReturn = () => {
        if (props.goBack) {
            props.goBack('REGISTRATION_FORM')
        } else {
            application.show_intro = true;
            user.login = false;
            localStorage.setItem('show_intro', 'true');
            history.push('/')
        }
    }
    return (<>
        <div className="AppContent">
            <div className="WrapPaiementResult">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-md-12 col-sm-12">
                            <a className="linkbacks" onClick={handleReturn}>
                                <img src={left_arrow_map} alt="" />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-12 col-sm-12 box-paiemnt">
                            <div className="title_gris text-center"></div>
                            <div className="title_inscri text-center">Entrez votre numéro mobile pour activer l'application</div>
                            <form>
                                <div className="form-group">
                                    <label for="prenom" className="labercarte">Numéro de télélphone</label>
                                    <PhoneInput disableDropdown inputProps={{ autoFocus: true }} placeholder="+216 21 000 000" masks={{ tn: '.. ... ...' }} onlyCountries={['tn']} country={'tn'} className="form-control formcarte" value={props.phone} onChange={props.setPhone} />
                                </div>

                                <div className="form-group">
                                    {props.phoneError && (<span className="phone-error">{props.phoneError}</span>)}
                                </div>


                            </form>
                            <button className="link_next" onClick={props.sendSms}> {props.loading ? <p style={{ color: 'white' }}>loading</p> : <img src={right_arrow_next} />}</button>
                            {props.status == "login" ?
                                <div className="actionlink">
                                    <Link className="link_sonnect" to={{ pathname: '/register' }}> Inscription </Link>
                                </div>
                                :
                                <div className="actionlink">
                                    <Link className="link_sonnect" to={{ pathname: '/login' }}> Se Connecter </Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default SubmitPhone
