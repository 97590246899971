import React from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import './index.css'
import moment from 'moment';


function Historiques({ pagination, getHistory, historiques }) {

  return (
    <>
      <div className="row headtab">
        <div className="col-xs-4">Date</div>
        <div className="col-xs-5">Partenaire</div>
        <div className="col-xs-3">Remise</div>

      </div>

      <InfiniteScroll
        dataLength={pagination.total}
        next={getHistory}
        hasMore={pagination.current_page < pagination.last_page}
        loader={<h4>Loading...</h4>}
      >
        {historiques && historiques.map((item, index) => (
          <div key={index} className="row bodytab">
            <div className="col-xs-4">{
              moment(item.time).format("YYYY/MM/DD")

            }  </div>
            <div className="col-xs-5">{item.partner_name}</div>
            <div className="col-xs-3">{item.promotion_discount ? item.promotion_discount + '%' : 'Gratuité'} </div>
          </div>
        ))}
      </InfiniteScroll>


    </>
  );
}



export default Historiques;