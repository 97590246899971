import React, { useState } from "react";
import { Link, withRouter } from 'react-router-dom'
import QrReader from 'react-qr-reader'
import { useHistory } from "react-router-dom";

import "./index.css";
import leftArrow from './../../assets/images/png/left_arrow.png'
import ImgScan from './../../assets/images/png/img_scan.png'
import API from "../../Backend/API";


const ScanQR = (props) => {
  const [showScanner, setShowScanner] = useState(false)
  const [error, setError] = useState('')
  let history = useHistory();


  const handleError = err => {
    console.error(err)
  }

  const handleScan = qrCode => {

    let id = '';
    if (props.location.state) {
      id = props.location.state.id
    }
    if (qrCode) {
      API.post(`client/scan-promotion?${id ? `promotion_id=${id}&` : ''}qrCode=${qrCode}`).then(res => {
        if (id) {
          history.push({
            pathname: `/promotion`,
            state: { qrCode: res.data.data }
          });
        } else {
          history.push({
            pathname: `/product/${res.data.data[0].partner_id}/${res.data.data[0].partner_name.toLowerCase().replace(/ /gi, '-')}`,
            state: { qrCode: qrCode }
          });
        }
      }).catch(error => {
        if (error.response.data.status_code == 404) {
          setError(error.response.data.message)
        } else {
          setError('QR code inncorrect')
        }
      })

      setShowScanner(false)
    }
  }

  const showScan = () => {
    setShowScanner(prev => !prev);
    setError('')
  }

  return (
    <div className="ScanWrapper">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12 col-sm-12">
            <Link className="linkBackn" to={{ pathname: '/' }}>
              <img src={leftArrow} alt="" />
            </Link>
          </div>
          <div className="col-xs-12 col-md-12 col-sm-12 boxScan text-center">
            <div className="TitleScan">Scannez le QR code affiché dans la boutique</div>
            <div id="boxImgScan" className={showScanner ? 'boxImgScan hidden' : 'boxImgScan'}>
              <span className="BeforeAfterScan">
                <img src={ImgScan} alt='' />
              </span>
            </div>
            <div className="boxCamScan text-center">
              {showScanner &&
                <QrReader
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: '280px' }}
                />
              }

              <p style={{ color: "white", marginBottom: "15px" }}>
                {error ? error : ''}
              </p>
            </div>
            <button className={showScanner ? 'btnScan hidden' : 'btnScan'} onClick={showScan} >{showScanner}</button>
          </div>
        </div>
      </div>

    </div>
  )
}

export default withRouter(ScanQR)