import React, { useState } from 'react'
import { withRouter } from "react-router-dom";

import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import {
    Map,
    InfoWindow,
    Marker,
    GoogleApiWrapper,
} from "google-maps-react";
const Index = (props) => {


    const [locations, setLocations] = useState(props.location?.state?.locations)
    const coords = {
        lat: locations[0]?.partner_location_gps.split(",")[0],
        lng: locations[0]?.partner_location_gps.split(",")[1]
    };

    const [textMarker, setTextMarker] = useState({
        address: '',
        name: '',
        show: false,
        activeMarker: null
    })

    function onMarkerClick(data, marker) {
        setTextMarker({
            address: data.address,
            name: data.name,
            show: true,
            activeMarker: marker,
        });
    }

    return (

        <div className="Containerpag paddingBottom">
            <Header />
            <div className="Map"  >
                <Map initialCenter={coords} zoom={14} defaultOptions={{ fullscreenControl: false }} google={props.google}  >

                    {
                        locations && locations.map((item, index) => {
                            return (
                                <Marker
                                    position={{
                                        lat: item.partner_location_gps.split(",")[0],
                                        lng: item.partner_location_gps.split(",")[1],
                                    }}
                                    key={index}
                                    id={index}
                                    icon={{
                                        path:
                                            "M7.47 5.47c4.627-4.627 12.128-4.627 16.755 0 4.615 4.631 4.615 12.124-.002 16.755l-7.81 7.81-.09.078c-.314.233-.758.206-1.042-.078l-7.811-7.81-.231-.238C2.845 17.345 2.922 10.018 7.47 5.47zm8.377 3.2c-2.65 0-4.8 2.149-4.8 4.8 0 2.65 2.15 4.8 4.8 4.8 2.58 0 4.684-2.034 4.796-4.586l.004-.214c0-2.58-2.034-4.683-4.586-4.795l-.214-.005z",
                                        fillColor: props.location?.state?.color,
                                        fillOpacity: 1,
                                        strokeWeight: 0,
                                        scale: 1,
                                    }}
                                    lat={item.partner_location_gps.split(",")[0]}
                                    lng={item.partner_location_gps.split(",")[1]}
                                    color={props.location?.state?.color}
                                    name={item.partner_location_nom}
                                    address={item.partner_location_addr}
                                    onClick={onMarkerClick}
                                />
                            )
                        })
                    }

                    <InfoWindow marker={textMarker.activeMarker} visible={textMarker.show}>
                        <div className="text-center">
                            <h4>{textMarker.name}</h4>
                            <p>{textMarker.address}</p>
                        </div>
                    </InfoWindow>
                </Map>
                <div className="resultmap">{locations.length} enseignes trouvés</div>
            </div>
            <Footer />
        </div>
    )
}

export default withRouter(GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
})(Index));