import React, { useState } from 'react'
import QRCode from 'qrcode.react'
import './index.css'
import { withRouter } from 'react-router'
import left_arrow_map from "../../assets/images/png/left_arrow-map.png";

const Index = (props) => {

    const [promotion, setPromotion] = useState(props.location.state.qrCode[0])

    return (

        <div className="scanned_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-12 col-sm-12">
                        <a className="linkbacks" href="/">
                            <img src={left_arrow_map} alt="" />
                        </a>
                    </div>
                    <div className="col-xs-12 col-md-12 col-sm-12 box-scanned text-center">
                        <div className="category_scan">{promotion.partner_name}</div>
                        <div className="promo_scan">{promotion.promotion_title}</div>
                        <div className="box_img_scanned">
                            <span className="before_after_scanned">
                                <QRCode size={180} value={promotion.qrCode} />
                            </span>
                            <div className="id_scanned">{promotion.qrCode}</div>
                        </div>
                        <div className="txt_code_partner">Montrez ce code au partenaire pour profiter de la remise</div>
                        <div className="txt_condition_qr">
                            Conditions :
                            Ce privilèges est valable jusqu'au {new Date(promotion.promotion_date_end).toISOString().split('T')[0]}
                            <p style={{ margin: 0 }} >                                Non cumulable avec d'autes promotions en cours
</p>
                            <p >Non comulable avec les chèques resto</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default withRouter(Index)