import React from 'react'

import OwlCarousel from 'react-owl-carousel';

import './../../assets/css/owl.carousel.css'
import './../../assets/css/owl.theme.default.css'

import './index.scss'

const IntroPresentation = props => {
    return (
        <>
            <div className="AppContent">
                <div className="WrapDemararage">
                    <OwlCarousel className='owl-theme' margin={0} items={1}>
                        {props.screens.map((item, index) => {
                            return (
                                <div key={index} className="item">
                                    <div className="sliderdem">
                                        <div className="logoprivilege">
                                            <img className="img-slider" src={item.logo} />
                                        </div>
                                        <div className="slider">
                                            <div className="titre1">{item.title}</div>
                                            <div className="titre2">{item.description}</div>
                                            <img className="" src={item.image} alt="" />
                                        </div>
                                    </div>
                                    <div className="actionlink">
                                        {item.next_button && (<><div className="linkBack" onClick={props.nextButton}> Suivant </div><div className="clearfix"></div></>)}
                                        {item.register_button && (<><div className="linkBack" onClick={props.registerButton}> Inscription </div><div className="clearfix"></div></>)}
                                        {item.login_button && (<><div className="link_sonnect" onClick={props.loginButton}> Se connecter </div><div className="clearfix"></div></>)}
                                    </div>
                                </div>
                            )
                        })}
                    </OwlCarousel>
                </div>
            </div>
        </>
    )
}

export default IntroPresentation