import React, { useState, useEffect, useMemo } from "react";
import { Link, NavLink } from 'react-router-dom'

import menu from "./../../assets/images/png/menu.png";
import logo from "./../../assets/images/png/logo.png";
import notif from "./../../assets/images/png/notif.png";
import leftArrow from './../../assets/images/png/left_arrow.png'
import logoNavbar from './../../assets/images/png/logo_navbar.png'
import xpand from './../../assets/images/png/xpand.png'


import './index.css'
import API from "../../Backend/API";

const Index = () => {
  const [sidebar, setSidebar] = useState(false)
  const [pages, setPages] = useState([])

  const toggleSidebar = () => {
    setSidebar(prev => !prev)
  }

  useEffect(() => {
    API.get(`/pages`).then(res => {
      setPages(res?.data?.data || [])
    })
  }, [])

  const listCategory = useMemo(() => pages.map((item, index) => {
    return (
      <li key={index + 1}>
        <NavLink tag={Link} onClick={toggleSidebar} className="link_navbar" to={`/page/${item.pages_slug}`}>
          {item.pages_titre}
        </NavLink>
      </li>
    )
  }), [pages])

  return (
    <div className="Wrap_Header">
      <div className="Header">
        <div className="toggle_icon" onClick={toggleSidebar}><img src={menu} alt="" /></div>
        <div className="logo_header"><Link to="/" ><img src={logo} alt="" /> </Link></div>
        {/* <div className="notif_icon"><img src={notif} alt="" /></div> */}
      </div>
      <div className="clearfix"></div>

      <div className={sidebar ? 'nav-menu active' : 'nav-menu'}>
        <div className="picto_back_navbar" onClick={toggleSidebar}>
          <img src={leftArrow} alt="leftArrow" />
        </div>
        <div className="clearfix"></div>
        <div className='box_logo'>
          <img src={logoNavbar} alt="logoNavbar" />
        </div>
        <div className="desc_top_navbar">
          Achetez malin et profitez de remises exceptionnelles !
        </div>
        <div className="comptename">
          <Link to="/client" >Mon compte <img src={xpand} className="pull-right" /> </Link>
          <div> <a className="name_compte">{localStorage.getItem('full_name') ? localStorage.getItem('full_name') : ''}    </a></div>
          <div> <p className="des_compte">Abonnement {localStorage.getItem('subscription') == 'true' ? 'actif' : 'expiré'}  </p></div>
        </div>
        <ul className="menu_navbar">
          <li>
            <NavLink className="link_navbar" to={{ pathname: '/' }}>Devenir partenaire</NavLink>
          </li>
          {listCategory}
        </ul>
      </div>
    </div>
  )
}
export default Index