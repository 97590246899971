import React, { Fragment } from "react";
import { Link, NavLink } from 'react-router-dom'
import home from "./../../assets/images/png/home.png";
import privillege from "./../../assets/images/png/privillege.png";
import scann from "./../../assets/images/png/scann.png";
import presdemoi from "./../../assets/images/png/pres_de_moi.png";
import './index.css'

const Index = () => {
  return (
    <div className="Footer">
      <NavLink className="link_footer" exact to={{ pathname: '/' }}>
        <span className="picto_footer"><img src={home} alt="" /></span>
        <span className="txt_footer">Nouveaux </span>
      </NavLink>
      <NavLink className="link_footer" to={{ pathname: '/categories' }}>
        <span className="picto_footer"><img src={privillege} alt="" /></span>
        <span className="txt_footer">Privilèges </span>
      </NavLink>
      <NavLink className="link_footer" to={{ pathname: '/scanqr' }}>
        <span className="picto_footer"><img src={scann} alt="" /></span>
        <span className="txt_footer">Scanner le code </span>
      </NavLink>
      <NavLink className="link_footer" to={{ pathname: '/map' }}>
        <span className="picto_footer"><img src={presdemoi} alt="" /></span>
        <span className="txt_footer">Près de moi </span>
      </NavLink>
    </div>
  )
}
export default Index