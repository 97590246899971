import React, { useState, useEffect, Component, useMemo } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import Loading from "./../../Loading";
import Header from "./../Header";
import Footer from "./../Footer";
import logo_privilege from "./../../assets/images/png/logo_privilege.png";
import logo_navbar from "./../../assets/images/png/logo_navbar.png";
import right_arrow_next from "./../../assets/images/png/right_arrow_next.png";
import left_arrow_map from "./../../assets/images/png/left_arrow-map.png";

import "./index.css";

function PaiementDetail() {
  return (
    <div className="AppContent">
      <div className="WrapPaiementDetail">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-12 col-sm-12">
              <a className="linkbacks" href="/">
                <img src={left_arrow_map} alt="" />
              </a>
            </div>
            <div className="col-xs-12 col-md-12 col-sm-12 box-paiemnt">
              <div className="title_gris text-center">Mode de paiement</div>
              <div className="title_inscri text-center">
                    Mode de paiement
       
                    </div>
                     <Link className="link_next" to={{ pathname:'/PaiementDetail'}}> 
                            <img src={right_arrow_next} />
                        </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaiementDetail;
