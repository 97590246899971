import React, { useContext, useEffect, useState } from 'react'
import IntroPresentation from './Presentation'

import logo from './../../assets/images/png/logo_privilege.png'
import image1 from './../../assets/images/png/comp_ecran_1.png'
import image2 from './../../assets/images/png/comp_ecran_2.png'
import { useHistory } from 'react-router'
import { userContext } from '../../Context/userContext'

const Intro = props => {

    const { application } = useContext(userContext)

    useEffect(() => {
        // Don't display this intro page again
        localStorage.setItem('show_intro', 'false')
        application.show_intro = false;

        // eslint-disable-next-line
    }, [])

    const [screens, setScreens] = useState([
        { logo, image: image1, title: 'Chaque jour des remises permanentes', description: 'Chez plus de 300 enseignes partenaires.', login_button: true, register_button: false, next_button: true },
        { logo, image: image2, title: 'Activez la géolocalisation', description: 'et découvrez les avantages près de vous.', login_button: true, register_button: true, next_button: false }
    ])
    const history = useHistory();

    const goToLogin = data => {
        props.setUpdate((state) => !state)
        history.push('/login')
    }
    const goToRegister = data => {
        props.setUpdate((state) => !state)
        history.push('/register')
    }
    const handleNextButton = data => {
        try {
            document.querySelector('.owl-dot.active').nextSibling.click()
        } catch (error) { }
    }

    return <IntroPresentation loginButton={goToLogin} registerButton={goToRegister} nextButton={handleNextButton} screens={screens} />
}

export default Intro