import React, { Component } from "react";
import Header from '../Header'
import Footer from '../Footer'
import API from '../../Backend/API'

import logo from "./../../logo.png";
import Loading from "../../Loading";
import $ from "jquery";


import { NavLink } from 'react-router-dom'
import { Avatar } from "@material-ui/core";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import "./index.css";
import { userContext } from "../../Context/userContext";
import InfiniteScroll from "react-infinite-scroll-component";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


class Index extends Component {

  state = {
    // Show or not he loading spinner
    loading: false,
    categories: [],
    selectedCategory: 1,
    pagination: {
      "total": null,
      "count": null,
      "per_page": null,
      "current_page": 0,
      "last_page": null
    },
    promotions: [],
    selectedTab: "Tab 1",
    value: 1,
    query: ''

  };

  handleChange = (event, newValue) => {

    this.setState({
      ...this.state,
      value: newValue,
      selectedCategory: newValue,
    });

    API.get(`/promotions?page=1&category=${newValue}${this.state.query}`).then((prom) => {

      this.setState({
        ...this.state,
        promotions: prom.data.data.data,
        pagination: prom.data.data.pagination,
        loading: false,
      });
    });

    return { ...this.state, data: this.state.promotion };
  };


  updateTab = (data) => {
    this.setState({ ...this.state, selectedTab: data.label });
  };

  getPosition = () => {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }


  getPromotions = async () => {
    let query = '';
    let latitude = localStorage.getItem('latitude');
    let longitude = localStorage.getItem('longitude');

    query = `&latitude=${latitude}&longitude=${longitude}`

    if (!latitude || !longitude) {
      await this.getPosition().then(res => {
        localStorage.setItem('latitude', res.coords.latitude)
        localStorage.setItem('longitude', res.coords.longitude)
        query = `&latitude=${res.coords.latitude}&longitude=${res.coords.longitude}`
      }).catch(res => {
        query = ''
      })
    }

    await API.get(`/promotions?page=${this.state.pagination.current_page + 1}&category=${this.state.selectedCategory}${query}`).then((res) => {


      this.setState({
        ...this.state,
        promotions: this.state.promotions.concat(res.data.data.data),
        pagination: res.data.data.pagination,
        query: query,
        loading: false,
      });

    }).catch((error) => {
      this.setState({
        ...this.state,
        loading: false
      })
    })
  }

  componentDidMount() {
    this.getPromotions();


    API.get(`/categories-partners`).then((cat) => {
      const selectedCategory = cat.data.data[0].selectedCategory ?? 1;
      const categories = cat.data.data;

      this.setState({
        ...this.state,
        categories,
        selectedCategory,
      });
    });



    return { ...this.state, data: this.state.promotion };
  }


  km(distance) {
    let dist = distance / 1000;
    return dist.toFixed(1) + " km"
  }



  render() {
    const { categories, promotions } = this.state;


    const CategoryList = categories.map((item, index) => {
      return (
        <Tab
          label={item.partner_category_name}
          value={item.partner_category_id}
          key={index}
          icon={<Avatar src={item.partner_category_icon} />}
        />
      );
    });
    const { index, direction } = this.state;
    return (
      <>
        <Header />
        <div className="tabs paddingBottom">
          <AppBar position="static" color="default">

            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              variant="scrollable"

              indicatorColor="primary"
              textColor="primary"
              aria-label="full width tabs example"

            >
              {CategoryList}
            </Tabs>
          </AppBar>

          <SwipeableViews
            axis={'x-reverse'}

            onChangeIndex={this.handleChange}
          >

            <TabPanel value={this.state.selectedCategory} index={this.state.selectedCategory} dir='x'>
              <InfiniteScroll
                dataLength={this.state.pagination.total}
                next={this.getPromotions}
                hasMore={this.state.pagination.current_page < this.state.pagination.last_page}
                loader={<h4>Loading...</h4>}
              >

                {
                  promotions.map((cartitems, index) => (
                    <div key={index} className="block_promo">
                      <div className="row">
                        <NavLink className="title_result_appart"
                          to={{
                            pathname: `/product/${cartitems.partner_id}/${cartitems.partner_name.toLowerCase().replace(/ /gi, '-')}`,
                          }}>
                          <div className="col-xs-3 col-sm-1">
                            <div className="img_promo">
                              <img
                                src={cartitems.partner_logo}
                                alt={cartitems.partner_name}
                              />
                            </div>
                          </div>
                          <div className="col-xs-9 col-sm-1">
                            <div className="titrepro">{cartitems.partner_name}</div>
                            {
                              cartitems.distance ?
                                (<div className="parag">à {this.km(cartitems.distance)} de votre position</div>)
                                :
                                ''
                            }
                            {cartitems.discount ? <div className="discount"> {cartitems.discount}% de remise </div> : ''}
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  ))
                }
              </InfiniteScroll>
            </TabPanel>



          </SwipeableViews>



        </div>
        <Footer />
      </>
    );
  }
}

export default Index;
