import React, { useState } from "react";
import { Link } from "react-router-dom";
import right_arrow_next from "./../../assets/images/png/right_arrow_next.png";
import left_arrow_map from "./../../assets/images/png/left_arrow-map.png";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import "./index.css";


function PaiementPhone() {
  const [phone, setPhone] = useState();
  return (
    <div className="AppContent">
      <div className="WrapPaiementResult">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-12 col-sm-12">
              <a className="linkbacks" href="/">
                <img src={left_arrow_map} alt="" />
              </a>
            </div>
            <div className="col-xs-12 col-md-12 col-sm-12 box-paiemnt">
              <div className="title_gris text-center">Mode de paiement</div>
              <div className="title_inscri text-center">
              Entrez votre numéro mobile pour activer l'application
       
                    </div>
                    <form>
                            <div className="form-group">
                                <label for="prenom" className="labercarte">Numéro de télélphone</label>
                                <PhoneInput   onlyCountries={['tn', 'fr', 'us']} country={'tn'} className="form-control formcarte"  value={phone} onChange={setPhone}   />
                            </div>
                           
                          
                    
                        </form>
                     <Link className="link_next" to={{ pathname:'/PaiementDetail'}}> 
                            <img src={right_arrow_next} />
                        </Link>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaiementPhone;
