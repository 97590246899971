import React, { useEffect, useState } from 'react'

import right_arrow_next from "./../../../assets/images/png/right_arrow_next.png";

const VerifySms = props => {

    const [show, setShow] = useState(true)

    useEffect(() => {
        if (!show) {
            var val = val || 60;
            if (val == 0) {
                setShow(true);
            }
            var timerMsg = "00:" + (val >= 10 ? val : "0" + val);
            var timer = document.getElementById("timer");
            timer.textContent = timerMsg;

            if (val-- <= 0) {
                clearInterval(timer);
            }
        }
    }, [show])

    const handleClick = () => {
        if (show) {
            setShow(false);
            props.resendCode()
        }
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const handleSubmit = () => {
        setShow(true);
        props.verifyCode();
    }
    return (<>
        <div className="container_inscri">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-12 col-sm-12">
                        <div className="top_inscri text-center">
                            <img src="assets/images/png/logo_navbar.png" alt="" />
                        </div>
                        <div className="title_inscri text-center">Entrer le code reçu par SMS pour connecter</div>
                        <div className="box_form_code">
                            <form>
                                <div className="box-for-code">
                                    <input maxLength="1" onInput={maxLengthCheck} autoComplete={"off"} onKeyUp={props.changeFocus} type="number" autoFocus className={"form-control form-code-club " + (props.codeError ? 'error' : '')} id="nbr1" name="code_1" />
                                </div>
                                <div className="box-for-code">
                                    <input maxLength="1" onInput={maxLengthCheck} autoComplete={"off"} onKeyUp={props.changeFocus} type="number" className={"form-control form-code-club " + (props.codeError ? 'error' : '')} id="nbr2" name="code_2" />
                                </div>
                                <div className="box-for-code">
                                    <input maxLength="1" onInput={maxLengthCheck} autoComplete={"off"} onKeyUp={props.changeFocus} type="number" className={"form-control form-code-club " + (props.codeError ? 'error' : '')} id="nbr3" name="code_3" />
                                </div>
                                <div className="box-for-code">
                                    <input maxLength="1" onInput={maxLengthCheck} autoComplete={"off"} onKeyUp={props.changeFocus} type="number" className={"form-control form-code-club " + (props.codeError ? 'error' : '')} id="nbr4" name="code_4" />
                                </div>
                            </form>
                            {props.codeError && (<div className="txt_error">{props.codeError}</div>)}
                        </div>
                        <div className="clearfix"></div>
                        <div className="box_next_link marg-top-80">
                            <a href="javascript:void(0)" className="link_next" onClick={handleSubmit}>
                                <img src={right_arrow_next} />
                            </a>
                        </div>
                        <div className="box_renvoyer_link text-center marg-top-55">
                            <div id="timer" style={{ display: show ? 'none' : 'block' }}></div>
                            <a href="javascript:void(0)" className="standard_link" onClick={handleClick}>Réenvoyer le code</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default VerifySms
