import axios from 'axios';
import { userContext } from '../Context/userContext'
import history from '../helpers/history';


const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL
});

const ApiStore = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL
});

ApiStore.interceptors.request.use(request => {
    request.headers = {
        ...request.request,
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + userContext.Provider._context._currentValue.user.token_store,
        'Accept-Language': localStorage.getItem('language') ?? 'en'
    }

    if (process.env.NODE_ENV === "development") {
        console.log('Sent request', request);
    }

    return request;
});

instance.interceptors.request.use(request => {
    request.headers = {
        ...request.request,
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + userContext.Provider._context._currentValue.user.token,
        'Accept-Language': localStorage.getItem('language') ?? 'en'
    }

    if (process.env.NODE_ENV === "development") {
        console.log('Sent request', request);
    }

    return request;
});

ApiStore.interceptors.response.use(response => {
    if (process.env.NODE_ENV === "development") {
        console.log('Received response:', response);
    }

    return response;
},
    async (error) => {
        if (error.response.status === 401) {
            return await axios.post(`${process.env.REACT_APP_BACKEND_URL}store/login`, {
                store_auth_email: process.env.REACT_APP_LKDSFDSF,
                store_auth_password: process.env.REACT_APP_AMENDSF,
            }).then(res => {
                userContext.Provider._context._currentValue.user.token_store = res.data.data.token.access_token;
                error.config.data = JSON.parse(error.config.data);

                return ApiStore.request(error.config);
            });
        }

        return Promise.reject(error);
    },
);

instance.interceptors.response.use(response => {
    if (process.env.NODE_ENV === "development") {
        console.log('Received response:', response);
    }

    return response;
},
    (error) => {
        if (error.response.status !== 401) {
            return Promise.reject(error);
        } else {

            localStorage.clear();
            localStorage.setItem('show_intro', 'true');
            userContext.Provider._context._currentValue.application.show_intro = true;
            userContext.Provider._context._currentValue.user.login = false;
            history.push('/login');
        }
    },
);


export { ApiStore, instance };
export default instance;
