import React, { useContext, useState } from 'react'
import './index.css'
import API from '../../Backend/API'
import { withRouter } from "react-router-dom";
import { Redirect, useHistory } from 'react-router';
import avatar_f from './../../assets/images/png/avatar_f.png'
import avatar_h from './../../assets/images/png/avatar_h.png'
import edit_picture from './../../assets/images/jpg/picto_edit.jpg'
import { userContext } from '../../Context/userContext';

const InfoClient = ({ client, setPage, getAge }) => {
    const { application, user } = useContext(userContext)

    const [redirect, setRedirect] = useState(false);

    const logout = () => {
        API.post(`/logout`).then((res) => {
            localStorage.clear();
            application.show_intro = true;
            user.login = false;
            localStorage.setItem('show_intro', 'true');
            setRedirect(true)
        })
    }

    const handleSubscribe = () => {
        API.post('/subscribe-web').then(response => {
            window.location = response.data.data.url_payment
        }).catch(error => {
            if ([404, 422, 203].includes(error.response.data.status_code)) {
                alert(error.response.data.message)
            }
        })
    }

    const handleUnsubscribe = () => {
        API.post('/confirm-unsubscribe').then(response => {
            localStorage.setItem('subscription', 'false');
            setRedirect(true)
        }).catch(error => {
            alert(error.response.data.message)
        })
    }

    if (redirect) {
        return <Redirect to="/" />
    }

    return (
        <>
            <div className="clearfix"></div>
            <div className="wrap_profile">
                <div className="img_profile">
                    {
                        client.client_gender == 'M' ?
                            (<img src={avatar_h} alt="" />)
                            :
                            (<img src={avatar_f} alt="" />)
                    }
                </div>
                <div className="title_profile">
                    {client.client_prenom + " " + client.client_nom}
                </div>
                <div className="sexe_profile">
                    {client.client_gender == 'M' ? 'Homme' : 'Femme'} . {getAge(client.client_age)}

                </div>
                <div className="tel_profile">
                    <a href="#?"> {client.client_telephone ? '+ 216 ' + client.client_telephone : ''}</a>
                </div>
                <button className="edit_profile" onClick={() => setPage(2)}>
                    <img src={edit_picture} alt="" />
                </button>
            </div>

            <div className="clearfix"></div>
            {
                client.client_subscribed ?
                    <div className="box_abonnement">
                        <label className="container_checkbox">Abonnement Actif
                            <input type="checkbox" checked="checked" />
                            <span className="checkmark"></span>
                        </label>
                        <div className="desc_abonnement">
                            3 jours gratuits puis 0.300 TND / jour à partir de votre solde téléphonique
                        </div>
                        <button className="btn_expired" onClick={handleUnsubscribe}>
                            Se désabonner
                        </button>
                    </div>
                    :
                    <div className="box_abonnement">
                        <label className="container_checkbox">Abonnement expiré
                        <input type="checkbox" checked="checked" />
                            <span className="checkmark"></span>
                        </label>
                        <div className="desc_abonnement">
                            <button className="btn_expired" onClick={handleSubscribe}>
                                Réactiver votre abonement
                        </button>
                        </div>
                    </div>
            }

            <div className="text-center">
                <button className="btn_club" onClick={logout}>
                    Se déconnecter
                        </button>
            </div>
        </>
    )
}

export default withRouter(InfoClient);