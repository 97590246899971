import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';

import Loading from "../../Loading";
import Header from '../Header'
import Footer from '../Footer'

import API from '../../Backend/API'

import imgdefault from './../../assets/images/jpg/default.jpg'

import './../../assets/css/owl.carousel.css'
import './../../assets/css/owl.theme.default.css'
import './index.css'

const Index = () => {

    const [state, setState] = useState({
        loading: true,
        cartridges: [],
        latitude: '',
        longitude: ''
    });

    useEffect(() => {
        getCartridgesList()

    }, [])

    const getPosition = () => {
        return new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
    }

    const getCartridgesList = async () => {

        let query = '';
        let latitude = localStorage.getItem('latitude');
        let longitude = localStorage.getItem('longitude');

        query = `&latitude=${latitude}&longitude=${longitude}`

        if (!latitude || !longitude) {
            await getPosition().then(res => {
                localStorage.setItem('latitude', res.coords.latitude)
                localStorage.setItem('longitude', res.coords.longitude)
                query = `&latitude=${res.coords.latitude}&longitude=${res.coords.longitude}`
            }).catch(res => {
                query = ''
            })
        }

        API.get(`cartridges?location=home${query}`).then(res => {
            setState({
                ...state,
                cartridges: res.data.data,
                loading: false
            })
        }).catch(error => {
            setState({
                ...state,
                loading: false
            })
        })

    }

    const km = (distance) => {
        let dist = distance / 1000;
        return dist.toFixed(1) + " km"
    }

    if (state.loading) {
        return <Loading />;
    } else {
        return (
            <div className="AppContent">
                <Header />
                <div className="WrapCartridges">
                    {state.cartridges && state.cartridges.map(item => {
                        return (

                            <div key={item.cartridge_id} className="content_result_cartridges">

                                <div className="container">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-12 col-sm-12">
                                            <div className="title_cartridges">{item.cartridge_title}</div>
                                            <div className={item.cartridge_position === 'v' ? "vertical_slide" : "horizontal_slide"}>

                                                <OwlCarousel className="owl-carousel-category"
                                                    loop={false}
                                                    margin={8}
                                                    items={item.cartridge_position === 'v' ? '2' : '1'}
                                                    nav={false}
                                                    dots={false}
                                                    autoplay={false}
                                                    stagePadding={24}
                                                >
                                                    {item.cartridge_items.map((cartitems) =>
                                                        <div key={cartitems.partner_id} className="item_carrousel">
                                                            <NavLink className="title_result_appart"
                                                                to={{
                                                                    pathname: `/product/${cartitems.partner_id}/${cartitems.partner_name.toLowerCase().replace(/ /gi, '-')}`,
                                                                }}>
                                                                <div className="box_item_cartridges">

                                                                    {cartitems.partner_location_images && cartitems.partner_location_images.map((locimg) =>
                                                                        <div key={Math.random()} className="img_partner_location">
                                                                            {locimg ? <img src={locimg} alt="" /> : <img src={imgdefault} alt="" />}
                                                                        </div>
                                                                    )}
                                                                    <div className="logo_partenaire">
                                                                        {!cartitems.partner_long ? <img src={cartitems.partner_logo} alt="" /> : <img src={imgdefault} alt="" />}
                                                                    </div>
                                                                </div>
                                                                <div className="box_content_cartridges">
                                                                    <div className="title_partner">{cartitems.partner_name} </div>
                                                                    <div className="category_partner">{cartitems.partner_category_name} </div>
                                                                    {cartitems.discount && <div className="discount_partner"> {cartitems.discount}% de remise sur l’addition</div>}

                                                                    {
                                                                        cartitems.distance ?
                                                                            <div className="parag">à {km(cartitems.distance)} de votre position</div>
                                                                            :
                                                                            ''
                                                                    }
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    )}
                                                </OwlCarousel>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
                <Footer />
            </div>
        );
    }
}

export default Index
