import React, { useEffect, useState } from 'react'
import Historiques from '../../Component/Historiques'
import Footer from '../../Component/Footer'
import Header from '../../Component/Header'
import InfoClient from '../../Component/InfoClient'
import EditClient from '../../Component/EditClient'
import API from '../../Backend/API'
import Loading from '../../Loading'

const Client = () => {

    const [page, setPage] = useState(0)

    const [historiques, setHistoriques] = useState([])
    const [client, setClient] = useState({})
    const [loading, setLoading] = useState(true)

    const [pagination, setPagination] = useState({
        "total": null,
        "count": null,
        "per_page": null,
        "current_page": 0,
        "last_page": null
    })

    const getHistory = async () => {

        await API.get(`/client/promotions-history?page=${pagination.current_page + 1}`).then((res) => {

            setHistoriques((prevState) => prevState.concat(res.data.data.data))
            setPagination(res.data.data.pagination)
        })
    }

    const getClient = async () => {

        await API.get('/client').then((res) => {
            setClient(res.data.data.client)
            localStorage.setItem('subscription', !!res.data.data.client.client_subscribed)

            setLoading(false);

        }).catch((error) => {
            setLoading(false);
        })
    }

    const getAge = (param) => {
        switch (param) {
            case 1:
                return '-18 ans';
            case 2:
                return '18-25 ans';
            case 3:
                return '26-35 ans';
            case 4:
                return '36-45 ans';
            case 5:
                return '+45 ans';
            default:
                return '';
        }
    }

    useEffect(() => {
        getClient()
        getHistory()
        // eslint-disable-next-line
    }, [])

    const getComponent = () => {
        switch (page) {
            case 1:
                return <Historiques pagination={pagination} getHistory={getHistory} historiques={historiques} />
            case 2:
                return <EditClient client={client} setClient={setClient} setPage={setPage} />
            default:
                return <InfoClient client={client} getAge={getAge} setPage={setPage} />;
        }

    }

    if (loading) {
        return <Loading />
    } else {
        return (
            <>


                <Header />
                <div className="WrapCartridges">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-12 col-sm-12">
                                {page !== 2 ?
                                    <ul className="list_app">
                                        <li onClick={() => setPage(0)} className={page === 0 ? 'active' : ''}><a href="#?">Mes informations</a></li>
                                        <li onClick={() => setPage(1)} className={page === 1 ? 'active' : ''}><a href="#?">Historique</a></li>
                                    </ul>
                                    :
                                    ''
                                }
                                {getComponent()}
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </>
        )
    }

}

export default Client
