import React, { useState, useEffect, useContext } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import { ApiStore } from '../../../Backend/API'
import SubmitPhone from '../Screens/SubmitPhone';
import Error from '../../Error';
import VerifySms from '../Screens/VerifySms';

import './../Screens/styles.scss'
import { userContext } from '../../../Context/userContext';

const Login = props => {
    // SUBMITTING_NUMBER, SUBMITTING_CODE
    const [step, setStep] = useState('SUBMITTING_NUMBER')
    const [phoneError, setPhoneError] = useState(false)
    const [codeError, setCodeError] = useState(false)
    const [phone, setPhone] = useState('216')
    const [loading, setLoading] = useState(false)
    const history = useHistory();

    const { user } = useContext(userContext)


    useEffect(() => {
        props.setUpdate((state) => !state)
        let search = window.location.search.match(/(\?|&)key\=([^&]*)/);
        if (search) {
            if (decodeURIComponent(search[2]) == 1) {
                localStorage.setItem('login', 'true')
                localStorage.setItem('subscription', 'true');
                history.push('/')
            } else {
                setStep('ERROR')
            }
        }
    }, [])




    const checkPhoneFormat = phone => {
        return /^216[0-9]{8}$/.test(phone)
    }

    const handleSendSms = event => {
        setPhoneError(false)
        if (checkPhoneFormat(phone)) {
            setLoading(true)

            ApiStore.post('send-code', { phone: phone.substr(3, 8) }).then(response => {
                setLoading(false)
                setStep('SUBMITTING_CODE')
            }).catch(error => {
                setLoading(false)
                if ([404, 422].includes(error.response.data.status_code)) {
                    setPhoneError(error.response.data.message)
                }
            })
        } else {
            setPhoneError('S\'il vous plaît mettre un numéro de téléphone valide')
        }
    }

    const changeFocus = e => {
        const key = e.key;
        const { maxLength, value, name } = e.target
        // eslint-disable-next-line
        const [fieldName, fieldIndex] = name.split('code_')


        if (key === "Backspace" || key === "Delete" || key === "ArrowLeft") {
            // if (value.length <= maxLength) {

            // Check if it's not the first input field
            if (parseInt(fieldIndex, 10) > 0) {
                // Get the previous input field
                const previousSibling = document.querySelector(
                    `input[name=code_${parseInt(fieldIndex, 10) - 1}]`
                )
                const currentInput = document.querySelector(
                    `input[name=code_${parseInt(fieldIndex, 10)}]`
                )
                // If found, focus the previous field
                if (previousSibling !== null) {
                    currentInput.value = ''
                    previousSibling.focus()
                }
            }
            // }
        } else {
            // Check if they hit the max character length
            if (value.length >= maxLength) {
                // Check if it's not the last input field
                if (parseInt(fieldIndex, 10) < 4) {
                    // Get the next input field
                    const nextSibling = document.querySelector(
                        `input[name=code_${parseInt(fieldIndex, 10) + 1}]`
                    )

                    // If found, focus the next field
                    if (nextSibling !== null) {
                        nextSibling.focus()
                    }
                }
            }
        }
    }


    const verifyCode = event => {
        setCodeError(false)
        let code = ''
        document.querySelectorAll('input').forEach(item => { code = code + item.value })
        ApiStore.post('verify-code-auth', {
            phone: phone.substr(3, 8), code, client_phone_id: Math.random(), client_phone_os: 4
        }).then(response => {
            user.login = true;
            user.token = response.data.data.token.access_token;
            localStorage.setItem('token', response.data.data.token.access_token)
            localStorage.setItem('full_name', response.data.data.client.client_prenom + ' ' + response.data.data.client.client_nom)
            localStorage.setItem('subscription', !!response.data.data.client.client_subscribed)
            localStorage.setItem('login', 'true')
            history.push('/')
        }).catch(error => {
            if ([410, 422].includes(error.response.data.status_code)) {
                setCodeError(error.response.data.message)
            }
        })
    }

    const resendCode = data => {

        setCodeError(false)
        document.querySelectorAll('input').forEach(item => { item.value = "" })
        document.querySelector('input').focus()

        handleSendSms()
    }

    switch (step) {
        case 'SUBMITTING_NUMBER':
            return <SubmitPhone loading={loading} status={"login"} phone={phone} setPhone={setPhone} phoneError={phoneError} sendSms={handleSendSms} />

        case 'SUBMITTING_CODE':
            return <VerifySms codeError={codeError} resendCode={resendCode} changeFocus={changeFocus} verifyCode={verifyCode} />

        case 'ERROR':
            return <Error data={props.location?.search.split('&')} />
        default:
            return (<>Please reload the page.</>)
    }
}

export default withRouter(Login)
