import React from 'react'
import ReactLoading from 'react-loading';
import logoclub from './assets/images/png/logo_carr.png';
const Loading = () => (
  <div className="pageloading">
    <img src={logoclub} alt="loadin" className="logoclub" />
    <div className="loadingpng">
      <ReactLoading type='spokes' color='#8b819d' height={27} width={28} /></div>
    <div className="copyright">© 2021 Bigdeal</div>
  </div>

);


export default Loading
