import React, {  } from "react";
import {Link} from 'react-router-dom'
import logo_navbar from './../../assets/images/png/logo_navbar.png'
import right_arrow_next from './../../assets/images/png/right_arrow_next.png'
import  './index.css'


function Paiement() {
  return (
    <div className="AppContent">
    <div className="WrapPaiement">
    <div className="container">
            <div className="row">
                <div className="col-xs-12 col-md-12 col-sm-12">
                <div className="top_logo text-center">
                        <img src={logo_navbar} alt="" />
                    
                    </div>
                    <div className="title_inscri text-center">
                    Mode de paiement
       
                    </div>
                    <div className="box_form_inscri">
                        <form>
                    
                     
                            <div className="groupe_radio">
                         
                      

                                <div className="inputs_radio">
                                    <label className="container_radio"><h3>Solde téléphonique</h3><p>
                                    Le montant de votre abonnement sera soustrait de votre solde téléphonique automatiquement chaque jour. Vous pouvez vous désabonner à tout moment.
                                    </p>
                                        <input type="radio" checked="checked" name="Paiement" />
                                        <span className="checkmark"></span>
                                    </label>
                    
                                </div>
                                </div>
                                <div className="groupe_radio">

                                <div className="inputs_radio">
                                    <label className="container_radio"><h3>Carte </h3>cadeau              <p>
                    Vous avez une carte cadeau? 
Entrez le numéro de votre carte pour activer votre abonnement.
                    </p>
                                        <input type="radio" checked="checked" name="Paiement" />
                                        <span className="checkmark"></span>
                                    </label>
                    
                                </div>
                                </div>
                                <div className="groupe_radio">
                                <div className="inputs_radio">
                                    <label className="container_radio"><h3>Paiement en ligne
                                      </h3> 
                                      <p>
                                      Vous payez un abonnement annuel directement en ligne. Votre abonnement sera activé automatiquement après le paiement.
                    </p>
                                        <input type="radio" checked="checked" name="Paiement" />
                                        <span className="checkmark"></span>
                                    </label>
                    
                                </div>
                            </div>
                            <div className="box_next_link marg-top-80">
                        <Link className="link_next" to={{ pathname:'/PaiementDetail'}}> 
                            <img src={right_arrow_next} />
                        </Link>
                    </div>
                        </form>
                    </div>


                  </div>
                  </div>
                  </div>

    </div>

  </div>
  );
}   



export default Paiement;