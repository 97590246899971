import React, { useEffect, useState } from 'react'

import Footer from '../Footer'
import Header from '../Header'
import Loading from '../../Loading'


import './index.css'
import API from '../../Backend/API'

const DetailsPage = ({ match: { params: { page_slug } } }) => {

  const [pageData, setPageData] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    API.get(`pages?page_slug=${page_slug}`).then(res => {

      setLoading(false)
      setPageData(res?.data?.data[0] || [])
    })
  }, [page_slug])

  return loading ? <Loading /> : (
    <div className="DetailProduct">
      <Header />
      <section className="section_page_details">
        <div className="WrapTitle">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-12 col-sm-12">
                <h2>{pageData?.pages_titre}</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-12 col-sm-12">
                <div className="ContentPage" dangerouslySetInnerHTML={{ __html: pageData?.pages_content }} />
              </div>
            </div>
          </div>
        </div>

      </section>
      <Footer />
    </div>
  )
}
export default DetailsPage